import Link from "next/link";

const MiniButton = (props) => {
  const handlerFuction = props.onClick || null;
  const type = props.type || "button";
  const link = props.link || null;

  if (type === "link") {
    return (
      <Link
        href={link}
        className="flex items-center justify-center w-10 h-8 transition-all ease-in bg-gray-100 rounded-md text-neutral-800 sm:w-14 sm:h-10 focus:ring-1 ring-primaryLight hover:bg-gray-200 duration-50"
      >
        {props.children}
      </Link>
    );
  }
  return (
    <button
      onClick={handlerFuction}
      className="flex items-center justify-center w-10 h-8 transition-all ease-in bg-gray-100 rounded-md sm:h-10 sm:w-14 focus:ring-1 ring-primaryLight hover:bg-gray-200 duration-50"
    >
      {props.children}
    </button>
  );
};

export default MiniButton;
