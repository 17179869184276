const getFAQSchema = (list) => {
  const questions = list.map((item) => ({
    "@type": "Question",
    name: item.question,
    acceptedAnswer: {
      "@type": "Answer",
      text: item.answer,
    },
  }));

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "FAQPage",
          mainEntity: [...questions],
        })}
      </script>
    </>
  );

  /*  return JSON.stringify(
    <script type="application/ld+json">
      {{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [...questions],
      }}
    </script>
  ); */
};

export default getFAQSchema;
