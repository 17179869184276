import Image from "next/image";
import React from "react";

const LogoBlack = () => {
  return (
    <Image
      src={`${process.env.NEXT_PUBLIC_DIRECTUS_URL}/assets/02f72493-a7e2-42b8-8aa6-e2a8e5fd94d9?a=1`}
      alt="agrowy logo"
      width={180}
      height={44}
      layout="fixed"
      priority
    />
  );
};

export default LogoBlack;
