import { Transition } from "@headlessui/react";
import dynamic from "next/dynamic";
import { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";

const NavigationSideBarHeader = dynamic(
  () => import("./NavigationSideBarHeader"),
  { ssr: false }
);
const NavigationSidebarMenu = dynamic(() => import("./NavigationSidebarMenu"), {
  ssr: false,
});

const NavigationSidebar = (props) => {
  const { locales, locale, localeLinks, show, handler, user } = props;
  const handlerFunction = () => {
    if (transition === true) {
      setTransition(false);
      return setTimeout(handler, 250);
    }
  };
  const [loaded, setLoaded] = useState(false);
  const [transition, setTransition] = useState(false);

  useEffect(() => {
    setLoaded(true);
    setTransition(show);
  }, [show]);

  if (show && loaded) {
    return ReactDOM.createPortal(
      <div className="fixed flex min-h-screen min-w-full bg-neutral-900 sm:bg-opacity-80 bg-opacity-0 z-50">
        <div onClick={handlerFunction} className="w-full hidden sm:block"></div>
        <Transition
          as={Fragment}
          show={transition}
          enter="transition ease-out duration-200"
          enterFrom="translate translate-x-full"
          enterTo="translate translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="translate translate-x-0"
          leaveTo="translate translate-x-full"
        >
          <div className="h-screen overflow-y overflow-x-hidden w-full sm:w-auto sm:min-w-min bg-white z-50 px-8 py-5">
            <NavigationSideBarHeader
              closeHandler={handlerFunction}
              locales={locales}
              locale={locale}
              localeLinks={localeLinks}
              user={user}
            />
            <NavigationSidebarMenu
              locale={locale}
              handlerFunction={handlerFunction}
            />
          </div>
        </Transition>
      </div>,
      document.getElementById("full-modal-root")
    );
  } else {
    return null;
  }
};

export default NavigationSidebar;
