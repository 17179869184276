import { Search } from "akar-icons";

const NavigationSearchBar = ({ placeholder, button, setState }) => {
  return (
    <div className="hidden lg:block flex-grow max-w-[500px] mx-4">
      <button
        onClick={() => {
          setState({ show: true });
        }}
        className="flex items-center w-full h-10 px-4 border border-gray-200 rounded-md outline-none cursor-pointer text-neutral-800 focus:ring-1 focus:border-zinc-900 ring-zinc-900"
      >
        <Search size={18} />
        <div className="ml-4 mr-4 text-sm font-light">
          {placeholder || "Ürün, firma, hastalık, bitki..."}
        </div>
        <div className="ml-auto text-sm font-semibold">{button || "ARA"}</div>
      </button>
    </div>
  );
};

export default NavigationSearchBar;
